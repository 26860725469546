import React from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import {Link} from 'gatsby'

const Properties = [
        {
            label: 'Blix',
            link1: 'Home',
            link2: 'Features',
            link3: 'Pricing',
            link7: 'BlueMail',
            link4: 'Download',
            link5: '',
            link6: '',
        },
        {
            label: 'About Us',
            // link1: '',
            // link2: '',
            // link3: '',
            // link4: '',
            link5: 'Contact Us',
            link6: 'Press Kit',
           
        },
        // {
        //     label: 'Community',
        //     link1: 'Home',
        //     link2: 'Download',
        //     link3: 'Apps',
        //     link4: 'Pricing',
        //     link5: 'Contact Us',
        // },
        // {
        //     label: 'Follow Us',
        //     link1: 'Home',
        //     link2: 'Download',
        //     link3: 'Apps',
        //     link4: 'Pricing',
        //     link5: 'Contact Us',
        // },
]

function Copyright() {
    let year = new Date()
  return (
    <Typography   variant="caption"  align="center" style={{color: '#fff'}}>
      © {year.getFullYear()} Blix Inc.
      <Link style={{ textDecoration: 'none', color: 'white' }} to='/tos' >   Terms of Service    
      </Link>
      {'  '}
      <Link style={{ textDecoration: 'none', color: 'white' }} to="/privacy"> 
        Privacy Policy
      </Link>
       </Typography>
  );
}

function FooterProperties() {
    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        {Properties.map((prop, i) => {
            return (
                <Grid key={i} item md={2} xs={12} sm={4} style={{fontWeight: 'bold', fontSize: '28px'}} className="footer-item-chunk">
                    {prop.label}
                    <Typography style={{marginTop: '19px', cursor: 'pointer'}}><Link to="/">{prop.link1}</Link></Typography>
                    <Typography style={{marginTop: '19px', cursor: 'pointer'}}><Link to="/features">{prop.link2}</Link></Typography>
                    <Typography style={{marginTop: '19px', cursor: 'pointer'}}><Link to="/pricing">{prop.link3}</Link></Typography>
                    <Typography style={{marginTop: '19px', cursor: 'pointer'}}><a href="https://bluemail.me">{prop.link7}</a></Typography>
                    <Typography style={{marginTop: '19px', cursor: 'pointer'}}><Link to="/download">{prop.link4}</Link></Typography>
                    <Typography style={{marginTop: '19px', cursor: 'pointer'}}><Link to="/contact-us">{prop.link5}</Link></Typography>
                    <Typography style={{marginTop: '19px', cursor: 'pointer'}}><a href="https://drive.google.com/drive/folders/1tO31HCkip9XvAZi7YJUuDhCHJBlORRDn">{prop.link6}</a></Typography>
                    
                </Grid>
            );
        })}
        </Grid>
    );
}

export default function StickyFooter() {
  return (
    <div className="footer-root">
        <footer className="footer">
            <Container maxWidth='lg' >
                <FooterProperties/>
            </Container>
        </footer>
        <div style={{padding: '31px', background: '#262626', textAlign: 'center'}}>
            <Container maxWidth={false} style={{padding: '0', color:'#fff'}}>
                <Copyright />
            </Container>
        </div>
    </div>
  );
}